import React, { useEffect, useRef, useState, createRef} from "react";
import {  db } from "../../firebase/firebase33";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'
import SignaturePad from 'react-signature-canvas'
import ReactDOM from 'react-dom';
import { doc, setDoc } from "firebase/firestore"; 
import {
    ref as ref2,
    uploadBytes,
    getDownloadURL,
    listAll,
    list,
    uploadBytesResumable,
    uploadString,
  } from "firebase/storage";
import { v4 } from "uuid";

import { imageDb } from "../../firebase/firebase33";
import { useScreenshot } from 'use-react-screenshot'
import { firebaseConfig } from '../../firebase/firebase33'


function HomeQuestion6(){
 

    const ptquestion1yesno = ['Yes', 'No'];
    // const ptquestion2yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion3yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion4yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion5yesno = ['First Choice', 'Second Choice', 'Third Choice'];

    const [physicianFirstName, setPhysicialFirstName] = useState("");
    const [physicianLastName, setPhysicianLastName] = useState("");
    const [dob, setDob] = useState("");
    const [homeZip, setHomeZip] = useState("");
    const [ptquestion1, setPtquestion1] = useState(ptquestion1yesno[0]);
    const { currentUser } = useAuth()
    const [screenshotImage, takeScreenshot] = useScreenshot()
    const [user, setUser] = useState(null);

    firebase.initializeApp(firebaseConfig);

    useEffect(() => {
        firebase
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.exists) {
              setUser(snapshot.data());
            } else {
              console.log("does not exist");
            }
          });
    }, []);

    console.log(user)


    const ref = createRef(null);
  const [width, setWidth] = useState(300);
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const sigPad = useRef();

    const clear = () => {
        sigPad.current.clear();
    };

    const trim = () => {
        setTrimmedDataURL(sigPad.current.getTrimmedCanvas().toDataURL('image/png'));
         takeScreenshot(ref.current)
    };
    

    function sleep(ms) {
        return new Promise(
          resolve => setTimeout(resolve, ms)
        );
      }

    const uploadFile = async () => {
        
        console.log("screenshot-->>>>", screenshotImage)
        const imageRef = ref2(imageDb, `signatures/${currentUser.uid}`);
        const fakeURL = screenshotImage.slice(22)
        console.log(fakeURL)
          uploadString(imageRef, fakeURL, 'base64').then(() => {
            alert('Image Uploaded')
          })
    
      };
    
    const  saveAnswer = async (event) => {
        event.preventDefault();

     
        // const userRef = doc(db, "patients" , currentUser.uid,)
        const userRef = doc(db, "users" , currentUser.uid,)


        await setDoc(userRef, {
            "PhysicianDOB":dob,
            "PhysicianHomeZip":homeZip,
            "AgreedtoTerms?": ptquestion1,
            'fitterID': 'jacksonrossi',
            'signTime': firebase.firestore.FieldValue.serverTimestamp(),
            'status':0

        
            

          }, { merge: true }).then(uploadFile());

          window.location.href="/home7"


      };
    
    if (user===null) {
        return <div>Loading</div>
    } else
    return (
        <>
         
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main   className="w-6/12 mt-20  flex self-center place-content-center place-items-center">
                <div  ref={ref} className="w-150  text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">{`Patient Acknowledgement Form`}</h3>
                        </div>
                        <div className="mt-2">
                            <h4 className="text-gray-800 text-lg ">{`Release of Medical Information`}</h4>
                        </div>

                    </div>
                    
                    
                    <p><b>Just 1 one more step before we can help you ease your pain....</b> <br></br>

</p>





                        {/* <div>
                            <input
                                type="text"
                               placeholder="Physician First Name"
                                required
                                value={physicianFirstName} onChange={(e) => { setPhysicialFirstName(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                               placeholder="Physician Last Name"
                                required
                                value={physicianLastName} onChange={(e) => { setPhysicianLastName(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div> */}

                    <p>I, {user.firstName} {user.lastname}, have initiated contact with {user.assignedDME} and/or its affiliate network (hereinafter referred to as "FB"). I select {user.assignedDME} as a supplier for my healthcare needs and give my full authorization to to bill Medicare, Medicaid, Medicare Supplemental and/or other insurer(s) or their agents or assigns on my behalf. I authorize the release of any medical or other information necessary for FB and {user.assignedDME} to process and submit my claims. I understand that by accepting this acknowledgment, I am giving authorization for the release of information that may be confidential, privileged and protected by the law. I authorize that payments for medical supplies furnished to me be paid directly to {user.assignedDME}. I agree that if my insurance company sends me any payments due, I will forward such payment immediately upon receipt to {user.assignedDME}. I agree that I may be responsible for payment of the cost of services that my insurance company does not pay. If there are any costs associated with the services being rendered, {user.assignedDME} will contact me to discuss my options at that time. I authorize  {user.assignedDME} and their affiliates to contact me periodically, by phone, email, text, regular mail, or otherwise, to determine my need for supplies, to conduct quality control surveys as required by Medicare or for any general marketing purposes. I authorize {user.assignedDME} to obtain medical or other information necessary in order to process my claim(s), including determining eligibility and seeking reimbursement for medical supplies provided.  </p>

<p><b>By entering your date of birth below and signing this document, you acknowledge and agree to be bound by the terms of this Release of Medical Information.
</b></p>
                    
<div>
<div>
                       
               
                </div>
                            <input
                                type="text"
                               placeholder="Date of Birth (MM/DD/YYYY)"
                                required
                                value={dob} onChange={(e) => { setDob(e.target.value) }}
                                className="w-60 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                        
                        <div class="container">
                    <div class="row  justify-content-center" >
                    
                    <div class="col-4">
                        <h2><b>Please Sign Below</b></h2>
                    </div>
                    <div >
                    {!trimmedDataURL &&(
                        <div style={{"width":"30%", backgroundColor: 'grey'}}>
                        <SignaturePad
                        ref={sigPad}
                        />
                    </div>
                    )}
                        {!trimmedDataURL && (
                        <div>
                        
                        <button  style ={{marginRight:'50px'}}onClick={trim}>
                            Sign
                            </button>
                            <button 
                                 onClick={clear}>
                            Clear
                            </button>
                            
                            
                        </div>
                        )}
                        {trimmedDataURL && (
                            <img  style = {{

                                'background-size': '200px 50px',
                                'width': '200px',
                                'height': '50px',
                                'background-color': 'white'
                            }} src={trimmedDataURL} alt="Signature" />
                        )}
                        </div>
                        {/* <button style={{ marginBottom: '10px' }} onClick={getImage}>
                            Take screenshot
                            </button> */}
                        {/* {screenshotImage &&  <img width={width} src={screenshotImage} alt={'Screenshot'} />} */}
                        
                    
                    </div>
                    </div>


                        {screenshotImage ? <button
                        
                            type="submit"
                            onClick={saveAnswer}
                            className='w-120 px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700  hover:shadow-xl transition duration-300'
                        > Next
                            
                        </button> :
                        <button
                        disabled
                            type="submit"
                            onClick={saveAnswer}
                            className='w-120 px-4 py-2 text-white font-medium rounded-lg bg-indigo-100  disabled hover:shadow-xl transition duration-300'
                        > Next
                            
                        </button>}
                        
                </div>
            </main>
        </>
    )

}
export default HomeQuestion6;