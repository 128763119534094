import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase33";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'

import { doc, setDoc } from "firebase/firestore"; 



function HomeQuestion10(){

    const diagnosedDiabeticYesNo = ['-','Yes', 'No'];
    const neuropathyYesNo = ['-','Yes', 'No'];
    const arthritisYesNo = ['-','Yes', 'No'];
    const carpalTunnelYesNo = ['-','Yes', 'No'];
    const tennisElbowYesNo = ['-','Yes', 'No'];
    const frozenShoulderYesNo = ['-','Yes', 'No'];
    const tendinitisYesNo = ['-','Yes', 'No'];
    const sciaticaYesNo = ['-','Yes', 'No'];
    const radiculopathyYesNo = ['-','Yes', 'No'];
    const goutYesNo = ['-','Yes', 'No'];
    const plantarFasciitisYesNo = ['-','Yes', 'No'];
    const tarsalTunnelYesNo = ['-','Yes', 'No'];
    const heights = ['-',`4'1`,`4'2`,`4'3`,`4'4`,`4'5`,`4'6`,`4'7`,`4'8`,`4'9`,`4'10`,`4'11`,`5'0`,`5'1`,`5'2`,`5'3`,`5'4`,`5'5`,`5'6`,`5'7`,`5'8`,`5'9`,`5'10`,`5'11`,`6'0`,`6'1`,`6'2`,`6'3`,`6'4`,`6'5`,`6'6`,`6'7`,`6'8`,`6'9`,`6'10`,`6'11`,`7'0`,`7'1`,`7'2`,`7'3`,`7'4`,`7'5`, ];
    


    const [primaryCarePhysician, setPrimaryCarePhysician] = useState("");
    const [dlv, setDlv] = useState("");
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [wasteSize, setWasteSize] = useState("");
    const [diagnosedDiabetic, setDiagnosedDiabetic] = useState(diagnosedDiabeticYesNo[0]);
    const [neuropathy, setNeuropathy] = useState(neuropathyYesNo[0]);
    const [arthritis, setArthritis] = useState(arthritisYesNo[0]);
    const [carpalTunnel, setCarpalTunnel] = useState(carpalTunnelYesNo[0]);
    const [tennisElbow, setTennisElbow] = useState(tennisElbowYesNo[0]);
    const [frozenShoulder, setFrozenShoulder] = useState(frozenShoulderYesNo[0]);
    const [tendinitis, setTendinitis] = useState(tendinitisYesNo[0]);
    const [sciatica, setSciatica] = useState(sciaticaYesNo[0]);
    const [radiculopathy, setRadiculopathy] = useState(radiculopathyYesNo[0]);
    const [gout, setGout] = useState(goutYesNo[0]);
    const [plantarFasciitis, setPlantarFasciitis] = useState(plantarFasciitisYesNo[0]);
    const [tarsalTunnel, setTarsalTunnel] = useState(tarsalTunnelYesNo[0]);
    const [conditionsNotListed, setConditionsNotListed] = useState('');
    const [conditionsNotListed2, setConditionsNotListed2] = useState('');
    const [conditionsNotListed3, setConditionsNotListed3] = useState('');
    const [medications, setMedications] = useState('');
    const [medication1, setMedication1] = useState('');
    const [medication2, setMedication2] = useState('');
    const [medication3, setMedication3] = useState('');
    const [medication4, setMedication4] = useState('');
    const [medication5, setMedication5] = useState('');
    const [medication6, setMedication6] = useState('');
    const [physicianCity, setPhysicianCity] = useState('');
    const [primaryCarePhysicianPhone, setPrimaryCarePhysicianPhone] = useState('');
    const [specialistName, setSpecialistName] = useState('');
    const [specialistPhone, setSpecialistPhone] = useState('');
    const [specialistDlv, setSpecialistDlv] = useState('');
    const [specialistCity, setSpecialistCity] = useState('');





    const { currentUser } = useAuth()

    
    const  saveAnswer = async (event) => {
        event.preventDefault();

     


        // const userRef = doc(db, "patients" , currentUser.uid,)
        const userRef = doc(db, "users" , currentUser.uid,)


        await setDoc(userRef, {
            diagnosedDiabetic,
            primaryCarePhysician,
            specialistName,
            dlv,
            specialistDlv,
            height,
            weight,
            wasteSize,
            physicianCity,
            specialistCity,
            primaryCarePhysicianPhone,
            specialistPhone,
            neuropathy,
            arthritis,
            carpalTunnel,
            tennisElbow,
            frozenShoulder,
            tendinitis,
            sciatica,
            radiculopathy,
            gout,
            plantarFasciitis,
            tarsalTunnel,
            conditionsNotListed,
            conditionsNotListed2,
            conditionsNotListed3,
            medication1,
            medication2,
            medication3,
            medication4,
            medication5,
            medication6,
            medications


          }, { merge: true });

        // const docRef = firebase.firestore().collection('users').doc(firebase.auth()?.currentUser?.uid).collection("codes")
        //       docRef.add({
        //         address,
        //         creation: firebase.firestore.FieldValue.serverTimestamp()
        //     })
         window.location.href="/home6"

      };
    
    //   return (
    //     <div className="container">
    //       <h1>What are some of your favorite tunes?</h1>
    //       <form onSubmit={saveAnswer}>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Street' 
    //         value={street}
    //         onChange={(e)=> {setStreet(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your City' 
    //         value={city}
    //         onChange={(e)=> {setCity(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Zip' 
    //         value={zip}
    //         onChange={(e)=> {setZip(e.target.value)}}
    //         ></input>
    //         <button type="submit">Submit to Firebase</button>
    //       </form>
    //     </div>
    //   );
    return (
        <>
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main className=" h-screen flex mt-20 self-center place-content-center place-items-center">
                <div className=" text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">Tell us About your Medical History</h3>
                        </div>

                    </div>
                    <div>
                            
                    <label className="text-sm mr-2 text-gray-600 font-bold">
                            Primary Care Physician Name

                            </label>
                            <input
                                type="text"
                               
                                required
                                value={primaryCarePhysician} onChange={(e) => { setPrimaryCarePhysician(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                            Date of Last Visit

                            </label>
                            <input
                                type="text"
                               
                                required
                                value={dlv} onChange={(e) => { setDlv(e.target.value) }}
                                className="mt-2 px-3 py-2 mr-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            
                        </div>
                        <div>
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                            Physician City

                            </label>
                            <input
                                type="text"
                               
                                required
                                value={physicianCity} onChange={(e) => { setPhysicianCity(e.target.value) }}
                                className="mt-2 px-3 py-2 mr-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                            Physician Phone Number

                            </label>
                            <input
                                type="text"
                               
                                required
                                value={primaryCarePhysicianPhone} onChange={(e) => { setPrimaryCarePhysicianPhone(e.target.value) }}
                                className="mt-2 px-3 py-2 mr-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            
                        </div>
                    <div>
                            
                    <label className="text-sm mr-2 text-gray-600 font-bold">
                            Specialist Name

                            </label>
                            <input
                                type="text"
                               
                                required
                                value={specialistName} onChange={(e) => { setSpecialistName(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                            Date of Last Visit

                            </label>
                            <input
                                type="text"
                               
                                required
                                value={specialistDlv} onChange={(e) => { setSpecialistDlv(e.target.value) }}
                                className="mt-2 px-3 py-2 mr-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            
                        </div>
                        <div>
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                            Specialist City

                            </label>
                            <input
                                type="text"
                               
                                required
                                value={specialistCity} onChange={(e) => { setSpecialistCity(e.target.value) }}
                                className="mt-2 px-3 py-2 mr-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                            Specialist Phone Number

                            </label>
                            <input
                                type="text"
                               
                                required
                                value={specialistPhone} onChange={(e) => { setSpecialistPhone(e.target.value) }}
                                className="mt-2 px-3 py-2 mr-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            
                        </div>
                    <div>
                            
                    <label className="text-sm mr-2 text-gray-600 font-bold">
                            Height (ft & in)

                            </label>
                            <select
                            onChange={(e) => setHeight(e.target.value)}
                            defaultValue={height}
                        >
                            {heights.map((painVal, idx) => (
                            <option key={idx}>{painVal}</option>
                            ))}
                        </select>
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                            Weight (lbs.)

                            </label>
                            <input
                                type="text"
                               
                                required
                                value={weight} onChange={(e) => { setWeight(e.target.value) }}
                                className="mt-2 px-3 py-2 mr-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                            Waist Size

                            </label>
                            <input
                                type="text"
                               
                                required
                                value={wasteSize} onChange={(e) => { setWasteSize(e.target.value) }}
                                className="mt-2 px-3 py-2 mr-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            
                        </div>







                    <div>
                    <label className="text-sm mr-2 text-gray-600 font-bold">
                            Diabetes

                            </label>
                 
                        <select
                            onChange={(e) => setTarsalTunnel(e.target.value)}
                            defaultValue={tarsalTunnel}
                        >
                            {tarsalTunnelYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        
                        
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                            Neuropathy 
                            </label>
                            <select
                            onChange={(e) => setNeuropathy(e.target.value)}
                            defaultValue={neuropathy}
                        >
                            {neuropathyYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                            Arthritis 
                            </label>
                            <select
                            onChange={(e) => setArthritis(e.target.value)}
                            defaultValue={arthritis}
                        >
                            {arthritisYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                     
                        </div>
                    <div>
                    <label className="text-sm mr-2 text-gray-600 font-bold">
                    Carpal Tunnel

                            </label>
                 
                        <select
                            onChange={(e) => setCarpalTunnel(e.target.value)}
                            defaultValue={carpalTunnel}
                        >
                            {carpalTunnelYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        
                        
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                        Tennis Elbow 
                            </label>
                            <select
                            onChange={(e) => setTennisElbow(e.target.value)}
                            defaultValue={tennisElbow}
                        >
                            {tennisElbowYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                        Frozen shoulder 
                            </label>
                            <select
                            onChange={(e) => setFrozenShoulder(e.target.value)}
                            defaultValue={frozenShoulder}
                        >
                            {frozenShoulderYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                     
                        </div>
                    <div>
                    <label className="text-sm mr-2 text-gray-600 font-bold">
                    Tendinitis

                            </label>
                 
                        <select
                            onChange={(e) => setTendinitis(e.target.value)}
                            defaultValue={tendinitis}
                        >
                            {tendinitisYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        
                        
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                        Sciatica
                            </label>
                            <select
                            onChange={(e) => setSciatica(e.target.value)}
                            defaultValue={sciatica}
                        >
                            {tennisElbowYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                        Radiculopathy
                            </label>
                            <select
                            onChange={(e) => setRadiculopathy(e.target.value)}
                            defaultValue={radiculopathy}
                        >
                            {radiculopathyYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                     
                        </div>
                    <div>
                    <label className="text-sm mr-2 text-gray-600 font-bold">
                    Tarsal tunnel

                            </label>
                 
                        <select
                            onChange={(e) => setTarsalTunnel(e.target.value)}
                            defaultValue={tarsalTunnel}
                        >
                            {tarsalTunnelYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        
                        
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                        Gout
                            </label>
                            <select
                            onChange={(e) => setGout(e.target.value)}
                            defaultValue={gout}
                        >
                            {goutYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                        Plantar fasciitis 
                            </label>
                            <select
                            onChange={(e) => setPlantarFasciitis(e.target.value)}
                            defaultValue={plantarFasciitis}
                        >
                            {plantarFasciitisYesNo.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                     
                        </div>

                   

                   
                    <div>
                            <label className="text-sm text-gray-600 font-bold">
                            Please List any Current Medical Conditions not Above 
                            </label>
                            
                        </div>
                        <div>
                        
                     
                           
                            
                            
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                            1. 
                            </label>
                            <input
                                type="text"
                               
                                
                                value={conditionsNotListed} onChange={(e) => { setConditionsNotListed(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                        2.                                </label>
                            <input
                                type="text"
                               
                                
                                value={conditionsNotListed2} onChange={(e) => { setConditionsNotListed2(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                        3.                                </label>
                            <input
                                type="text"
                               
                                
                                value={conditionsNotListed3} onChange={(e) => { setConditionsNotListed3(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                    <div>
                            <label className="text-sm text-gray-600 font-bold">
                            Please List all Current Medications 
                            </label>
                            
                        </div>
                        <div>
                        
                     
                           
                            
                            
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                            1. 
                            </label>
                            <input
                                type="text"
                               
                                
                                value={medication1} onChange={(e) => { setMedication1(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                        2.                                </label>
                            <input
                                type="text"
                               
                                
                                value={medication2} onChange={(e) => { setMedication2(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                        3.                                </label>
                            <input
                                type="text"
                               
                                
                                value={medication3} onChange={(e) => { setMedication3(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                        <div>
                        
                     
                           
                            
                            
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                            4. 
                            </label>
                            <input
                                type="text"
                               
                                
                                value={medication4} onChange={(e) => { setMedication4(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                        5.                                </label>
                            <input
                                type="text"
                               
                                
                                value={medication5} onChange={(e) => { setMedication5(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                        6.                                </label>
                            <input
                                type="text"
                               
                                
                                value={medication6} onChange={(e) => { setMedication6(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                    {/* <div>
                            <label className="text-sm text-gray-600 font-bold">
                            Please List all Current Medications 
                            </label>
                            <input
                                type="text"
                               
                                required
                                value={medications} onChange={(e) => { setMedications(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div> */}
                    







                   

                        <button
                            type="submit"
                            onClick={saveAnswer}
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Next Step
                            
                        </button>
                        
                </div>
            </main>
        </>
    )
}
export default HomeQuestion10;