import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase33";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'
import { firebaseConfig } from '../../firebase/firebase33'

import { doc, setDoc } from "firebase/firestore"; 



function HomeQuestion14(){
    const [user, setUser] = useState(null);


    firebase.initializeApp(firebaseConfig);

    

    


    useEffect(() => {
          firebase
            .firestore()
            .collection("users")
            .doc(currentUser.uid)
            .get()
            .then((snapshot) => {
              if (snapshot.exists) {
                setUser(snapshot.data());
              } else {
                console.log("does not exist");
              }
            });
      }, []);
    const diagnosedDiabeticYesNo = ['-','Yes', 'No'];
    
    const tarsalTunnelYesNo = ['-','Yes', 'No']
    const howLongHaveYouHadThisPainQuestions = ['-','A Week', 'A Month', "Longer"]
    const isThePainAnswers = ['-',"Present all the time", "Just occasionally", 'Activity Related']
    const isThePainAnswers2 = ['-',"Burning", "Stabbing/Sharp", 'Radiating (travels to other body areas)', "Throbbing" ]
    const forThisPartOfTheBodyAnswers = ['-',"XRay", "Injections", "MRI", "CT Scan", "All of The Above"]
    const medicationAnswers = ['-', "Yes", "No", "Sometimes"]
    




   
    const [complaintDuetoAnInjury, setComplaintDuetoAnInjury] = useState(diagnosedDiabeticYesNo[0]);
    const [previousSurgeries, setPreviousSurgeries] = useState(diagnosedDiabeticYesNo[0]);
    const [howLongHaveYouHadThisPain, setHowLongHaveYouHadThisPain] = useState(howLongHaveYouHadThisPainQuestions[0]);
    const [isThePain, setIsThePain] = useState(isThePainAnswers[0]);
    const [isThePain2, setIsThePain2] = useState(isThePainAnswers2[0]);
    const [forThisPartOfTheBody, setForThisPartOfTheBody] = useState(forThisPartOfTheBodyAnswers[0]);
    const [medication, setMedication] = useState(medicationAnswers[0]);
    const [treatment1, setTreatment1] = useState("")
  
    
    
    
    
    
    const [dateOfInjury, setDateOfInjury] = useState('');
    const [dateOfSurgery, setDateOfSurgery] = useState('');
//////////////////////////////////////////////////////////////





    const [complaintDuetoAnInjury2, setComplaintDuetoAnInjury2] = useState(diagnosedDiabeticYesNo[0]);
    const [previousSurgeries2, setPreviousSurgeries2] = useState(diagnosedDiabeticYesNo[0]);
    const [howLongHaveYouHadThisPain2, setHowLongHaveYouHadThisPain2] = useState(howLongHaveYouHadThisPainQuestions[0]);
    const [isThePain22, setIsThePain22] = useState(isThePainAnswers[0]);
    const [isThePain222, setIsThePain222] = useState(isThePainAnswers2[0]);
    const [forThisPartOfTheBody2, setForThisPartOfTheBody2] = useState(forThisPartOfTheBodyAnswers[0]);
    const [medication2, setMedication2] = useState(medicationAnswers[0]);
    const [treatment12, setTreatment12] = useState("")
    const [previousTreatments1, setPreviousTreatments1] = useState("")
    const [previousTreatments2, setPreviousTreatments2] = useState("")
    const [previousTreatments3, setPreviousTreatments3] = useState("")
    const [previousTreatments4, setPreviousTreatments4] = useState("")
    const [previousTreatments5, setPreviousTreatments5] = useState("")
    const [previousTreatments6, setPreviousTreatments6] = useState("")
    const [previous2Treatments1, setPrevious2Treatments1] = useState("")
    const [previous2Treatments2, setPrevious2Treatments2] = useState("")
    const [previous2Treatments3, setPrevious2Treatments3] = useState("")
    const [previous2Treatments4, setPrevious2Treatments4] = useState("")
    const [previous2Treatments5, setPrevious2Treatments5] = useState("")
    const [previous2Treatments6, setPrevious2Treatments6] = useState("")
  
    
    
    
    
    
    const [dateOfInjury2, setDateOfInjury2] = useState('');
    const [dateOfSurgery2, setDateOfSurgery2] = useState('');




    const { currentUser } = useAuth()

    
    const  saveAnswer = async (event) => {
        event.preventDefault();

     


        // const userRef = doc(db, "patients" , currentUser.uid,)
        const userRef = doc(db, "users" , currentUser.uid,)


        await setDoc(userRef, {
            complaintDuetoAnInjury,
            previousSurgeries,
            howLongHaveYouHadThisPain,
            dateOfInjury,
            dateOfSurgery,
            isThePain,
            isThePain2,
            forThisPartOfTheBody,
            medication,
            treatment1,
            complaintDuetoAnInjury2,
            previousSurgeries2,
            howLongHaveYouHadThisPain2,
            dateOfInjury2,
            dateOfSurgery2,
            isThePain2,
            isThePain22,
            forThisPartOfTheBody2,
            medication2,
            treatment12,
            previousTreatments1,
            previousTreatments2,
            previousTreatments3,
            previousTreatments4,
            previousTreatments5     ,
            previousTreatments6,
            previous2Treatments1,
            previous2Treatments2,
            previous2Treatments3,
            previous2Treatments4,
            previous2Treatments5,
            previous2Treatments6
          }, { merge: true });

        // const docRef = firebase.firestore().collection('users').doc(firebase.auth()?.currentUser?.uid).collection("codes")
        //       docRef.add({
        //         address,
        //         creation: firebase.firestore.FieldValue.serverTimestamp()
        //     })
         window.location.href="/home10"

      };
    
    //   return (
    //     <div className="container">
    //       <h1>What are some of your favorite tunes?</h1>
    //       <form onSubmit={saveAnswer}>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Street' 
    //         value={street}
    //         onChange={(e)=> {setStreet(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your City' 
    //         value={city}
    //         onChange={(e)=> {setCity(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Zip' 
    //         value={zip}
    //         onChange={(e)=> {setZip(e.target.value)}}
    //         ></input>
    //         <button type="submit">Submit to Firebase</button>
    //       </form>
    //     </div>
    //   );

     

      if (user){
        return (
            <>
                {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}
    
                <main className="  flex mt-20 place-content-center place-items-center">
                    <div className=" text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                        <div className="text-center mb-6">
                            <div className="mt-2">
                                <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">Tell us About your {user.painAreas[0]} Pain (contd.)</h3>
                            </div>
    
                        </div>
    
    
    
    
    
    
                        <div>
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                                Complaint due to an Injury?
    
                                </label>
                     
                            <select
                                onChange={(e) => setComplaintDuetoAnInjury(e.target.value)}
                                defaultValue={complaintDuetoAnInjury}
                            >
                                {tarsalTunnelYesNo.map((yesno, idx) => (
                                <option key={idx}>{yesno}</option>
                                ))}
                            </select>
                            
                            
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                                Year of injury: 
                                </label>
                                {complaintDuetoAnInjury == 'Yes' ?

                                <input
                                    type="text"
                                   
                                    required
                                    value={dateOfInjury} onChange={(e) => { setDateOfInjury(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                /> :

                                <input
                                    type="text"
                                   
                                    disabled
                                    value={dateOfInjury} onChange={(e) => { setDateOfInjury(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />

      }
                                
                           
    
                        
                        
                        
                            
                        
                       
                                
                            </div>
                        <div>
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                                Any previous surgeries for this issue?
    
                                </label>
                     
                            <select
                                onChange={(e) => setPreviousSurgeries(e.target.value)}
                                defaultValue={previousSurgeries}
                            >
                                {tarsalTunnelYesNo.map((yesno, idx) => (
                                <option key={idx}>{yesno}</option>
                                ))}
                            </select>
                            
                            
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                                Year of Surgery: 
                                </label>
                                {previousSurgeries == 'Yes' ?

                                <input
                                    type="text"
                                   
                                    required
                                    value={dateOfSurgery} onChange={(e) => { setDateOfSurgery(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                /> :
                                <input
                                    type="text"
                                   
                                    disabled
                                    value={dateOfSurgery} onChange={(e) => { setDateOfSurgery(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
      }
                            </div>
                                            
                            <div>
                        <label className="text-sm mr-5 text-gray-600 font-bold">
                                Pain Frequency:
    
                                </label>
                     
                                <select
                                className="mr-10 "
                                onChange={(e) => setIsThePain(e.target.value)}
                                defaultValue={isThePain}
                            >
                                {isThePainAnswers.map((yesno, idx) => (
                                <option key={idx}>{yesno}</option>
                                ))}
                            </select>
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                                Type of Pain:
    
                                </label>
                     
                                <select
                                onChange={(e) => setIsThePain2(e.target.value)}
                                defaultValue={isThePain2}
                            >
                                {isThePainAnswers2.map((yesno, idx) => (
                                <option key={idx}>{yesno}</option>
                                ))}
                            </select>
                            
                            
                        
                            </div>
                            <div>
                        <label className="text-sm mr-5 text-gray-600 font-bold">
                                For this body part, have you had
    
                                </label>
                     
                                <select
                                className="mr-10 "
                                onChange={(e) => setForThisPartOfTheBody(e.target.value)}
                                defaultValue={forThisPartOfTheBody}
                            >
                                {forThisPartOfTheBodyAnswers.map((yesno, idx) => (
                                <option key={idx}>{yesno}</option>
                                ))}
                            </select>
                        <label className="text-sm  text-gray-600 font-bold">
                        Does medication help with the pain:
    
                                </label>
                     
                                <select
                                onChange={(e) => setMedication(e.target.value)}
                                defaultValue={medication}
                                
                            >
                                {medicationAnswers.map((yesno, idx) => (
                                <option key={idx}>{yesno}</option>
                                ))}
                            </select>
                            
                            
                        
                            </div>
                        
    
                        
                        
                        
                        <div class="col-4">
                            <h2><b>How long have you had this pain?</b></h2>
                        </div>
                        <div class="col-4">
                            <select
                                onChange={(e) => setHowLongHaveYouHadThisPain(e.target.value)}
                                defaultValue={howLongHaveYouHadThisPain}
                            >
                                {howLongHaveYouHadThisPainQuestions.map((yesno, idx) => (
                                <option key={idx}>{yesno}</option>
                                ))}
                            </select>
                            </div>
                        <div class="container">
                        <div class="row  justify-content-center">
                        
                        <div class="col-4">
                            <h2><b>List all Treatments Previously Tried</b></h2>
                            <h3>(i.e., prescribed meds, physical therapy, electro-therapy, </h3>
                            <h3>   heat/ice, ointments):</h3>
                        </div>
                        <div>
                        
                     
                           
                            
                            
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                                Treatment 1. 
                                </label>
                                <input
                                    type="text"
                                   
                                    required
                                    value={previousTreatments1} onChange={(e) => { setPreviousTreatments1(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                            Treatment 2.                                </label>
                                <input
                                    type="text"
                                   
                                    required
                                    value={previousTreatments2} onChange={(e) => { setPreviousTreatments2(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                            </div>
                        <div>
                        
                     
                           
                            
                            
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                                Treatment 3. 
                                </label>
                                <input
                                    type="text"
                                   
                                    required
                                    value={previousTreatments3} onChange={(e) => { setPreviousTreatments3(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                            Treatment 4.                                </label>
                                <input
                                    type="text"
                                   
                                    required
                                    value={previousTreatments4} onChange={(e) => { setPreviousTreatments4(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                            </div>
                        <div>
                        
                     
                           
                            
                            
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                                Treatment 5. 
                                </label>
                                <input
                                    type="text"
                                   
                                    required
                                    value={previousTreatments5} onChange={(e) => { setPreviousTreatments5(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                            Treatment 6.                                </label>
                                <input
                                    type="text"
                                   
                                    required
                                    value={previousTreatments6} onChange={(e) => { setPreviousTreatments6(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                            </div>
                        {/* <div>
                                
                                <input
                                    type="text"
                                   
                                    
                                    value={treatment1} onChange={(e) => { setTreatment1(e.target.value) }}
                                    className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                            </div>
                    
                                
                                <input
                                    type="text"
                                   
                                    
                                    value={treatment1} onChange={(e) => { setTreatment1(e.target.value) }}
                                    className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                /> */}
                        </div>
                        </div>
    
    
    
                        { user.painAreas.length === 2 ? 
                        <>
                        <div className="text-center mb-6">
                            <div className="mt-2">
                                <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">Tell us About your {user.painAreas[1]} Pain </h3>
                            </div>
    
                        </div>
                        <div>
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                                Complaint due to an Injury?
    
                                </label>
                     
                            <select
                                onChange={(e) => setComplaintDuetoAnInjury2(e.target.value)}
                                defaultValue={complaintDuetoAnInjury2}
                            >
                                {tarsalTunnelYesNo.map((yesno, idx) => (
                                <option key={idx}>{yesno}</option>
                                ))}
                            </select>
                            
                            
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                                Year of injury: 
                                </label>
                                {complaintDuetoAnInjury2 == 'Yes' ?
                                <input
                                type="text"
                               
                                required
                                value={dateOfInjury2} onChange={(e) => { setDateOfInjury2(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            /> :
                            <input
                                    type="text"
                                   
                                    disabled
                                    value={dateOfInjury2} onChange={(e) => { setDateOfInjury2(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                            
                            
                            }
                                
                                
                           
    
                        
                        
                        
                            
                        
                       
                                
                            </div>
                        <div>
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                                Any previous surgeries for this issue?
    
                                </label>
                     
                            <select
                                onChange={(e) => setPreviousSurgeries2(e.target.value)}
                                defaultValue={previousSurgeries2}
                            >
                                {tarsalTunnelYesNo.map((yesno, idx) => (
                                <option key={idx}>{yesno}</option>
                                ))}
                            </select>
                            
                            

                            <label className="text-sm mr-2 text-gray-600 font-bold">
                                Year of Surgery: 


                                </label>
                                {previousSurgeries2 == 'Yes' ?
                                <input
                                    type="text"
                                   
                                    required
                                    value={dateOfSurgery2} onChange={(e) => { setDateOfSurgery2(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                                :

                                <input
                                type="text"
                               
                                disabled
                                value={dateOfSurgery2} onChange={(e) => { setDateOfSurgery2(e.target.value) }}
                                className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />



      }
                            </div>
       
                                            
                            <div>
                        <label className="text-sm mr-5 text-gray-600 font-bold">
                                Is the Pain:
    
                                </label>
                     
                                <select
                                className="mr-10 "
                                onChange={(e) => setIsThePain22(e.target.value)}
                                defaultValue={isThePain22}
                            >
                                {isThePainAnswers.map((yesno, idx) => (
                                <option key={idx}>{yesno}</option>
                                ))}
                            </select>
                        <label className="text-sm mr-2 text-gray-600 font-bold">
                                Is the Pain:
    
                                </label>
                     
                                <select
                                onChange={(e) => setIsThePain222(e.target.value)}
                                defaultValue={isThePain222}
                            >
                                {isThePainAnswers2.map((yesno, idx) => (
                                <option key={idx}>{yesno}</option>
                                ))}
                            </select>
                            
                            
                        
                            </div>
                            <div>
                        <label className="text-sm mr-5 text-gray-600 font-bold">
                                For this body part, have you had
    
                                </label>
                     
                                <select
                                className="mr-10 "
                                onChange={(e) => setForThisPartOfTheBody2(e.target.value)}
                                defaultValue={forThisPartOfTheBody2}
                            >
                                {forThisPartOfTheBodyAnswers.map((yesno, idx) => (
                                <option key={idx}>{yesno}</option>
                                ))}
                            </select>
                        <label className="text-sm  text-gray-600 font-bold">
                        Does medication help with the pain:
    
                                </label>
                     
                                <select
                                onChange={(e) => setMedication2(e.target.value)}
                                defaultValue={medication2}
                                
                            >
                                {medicationAnswers.map((yesno, idx) => (
                                <option key={idx}>{yesno}</option>
                                ))}
                            </select>
                            
                            
                        
                            </div>
                        
    
                        
                        
                        
                        <div class="col-4">
                            <h2><b>How long have you had this pain?</b></h2>
                        </div>
                        <div class="col-4">
                            <select
                                onChange={(e) => setHowLongHaveYouHadThisPain2(e.target.value)}
                                defaultValue={howLongHaveYouHadThisPain2}
                            >
                                {howLongHaveYouHadThisPainQuestions.map((yesno, idx) => (
                                <option key={idx}>{yesno}</option>
                                ))}
                            </select>
                            </div>
                        <div class="container">
                        <div class="row  justify-content-center">
                        <div class="col-4">
                            <h2><b>List all Treatments Previously Tried</b></h2>
                            <h3>(i.e., prescribed meds, physical therapy, electro-therapy, </h3>
                            <h3>   heat/ice, ointments):</h3>
                        </div>
                        <div>
                        
                     
                           
                            
                            
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                                Treatment 1. 
                                </label>
                                <input
                                    type="text"
                                   
                                    required
                                    value={previous2Treatments1} onChange={(e) => { setPrevious2Treatments1(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                            Treatment 2.                                </label>
                                <input
                                    type="text"
                                   
                                    required
                                    value={previous2Treatments2} onChange={(e) => { setPrevious2Treatments2(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                            </div>
                        <div>
                        
                     
                           
                            
                            
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                                Treatment 3. 
                                </label>
                                <input
                                    type="text"
                                   
                                    required
                                    value={previous2Treatments3} onChange={(e) => { setPrevious2Treatments3(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                            Treatment 4.                                </label>
                                <input
                                    type="text"
                                   
                                    required
                                    value={previous2Treatments4} onChange={(e) => { setPrevious2Treatments4(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                            </div>
                        <div>
                        
                     
                           
                            
                            
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                                Treatment 5. 
                                </label>
                                <input
                                    type="text"
                                   
                                    required
                                    value={previous2Treatments5} onChange={(e) => { setPrevious2Treatments5(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                            <label className="text-sm mr-2 text-gray-600 font-bold">
                            Treatment 6.                                </label>
                                <input
                                    type="text"
                                   
                                    required
                                    value={previous2Treatments6} onChange={(e) => { setPrevious2Treatments6(e.target.value) }}
                                    className="mt-2 px-3 mr-2 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                            </div>
                        <div>
                                
                                
                            </div>
                        </div>
                        </div>
                        </> :
    
                        <div></div> }
                    
                        
                            <button
                                type="submit"
                                onClick={saveAnswer}
                                className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                            > Next Step
                                
                            </button>
                            
                    </div>
                </main>
            </>
        )
      }
    
}
export default HomeQuestion14;